import React from 'react';
import TechnologyContentHeader from '../TechnologyContentHeader';
import TechnologyContentHighlighter from '../TechnologyContentHighlighter';
import TechnologyContentServices from '../TechnologyContentServices';
import TechnologyContentNextTechnology from '../TechnologyContentNextTechnology';
import { myStyles } from './style';
import './TechnologyContent.scss';
import clsx from 'clsx';
import RequestFormPopup from '../RequestFormPopup';

const TechnologyContent = ({ data, pageContext, basePath, images }) => {
  const classes = myStyles(images?.leftDonutBackgroundImage);
  return (
    <div className="technology-content-main">
      <div className={clsx('bg-left-donut', classes['bg-left-donut'])}>
        <TechnologyContentHeader
          images={images}
          drodownListData={pageContext}
          basePath={basePath}
          slug={data?.slug}
          headerSectionTitle={data?.headerSectionTitle}
          headerSectionDescription={data?.headerSectionDescription?.raw}
          heroImage={data?.heroImage?.file?.url+'?fm=webp&q=100'}
          heroImageDescription={data?.heroImage?.description}
          shortdescription={data?.technologyStandAloneIntroSection?.shortDescription?.raw}
          introSectionImage={data?.introSectionImage?.file?.url+'?fm=webp&q=100'}
          description={data?.introSectionImage?.description}
          imageHeader={data?.imageHeader}
          introSection={data?.technologyStandAloneIntroSection}
          header={data?.technologyStandAloneIntroSection?.header}
          subHeader={data?.technologyStandAloneIntroSection?.subHeader}
        />
        <TechnologyContentHighlighter
          highlighterSectionImage={data?.highlighterSectionImage}
          highlighterSectionTitle={data?.highlighterSectionTitle}
        />
      </div>
      <TechnologyContentServices data={data} />
      <TechnologyContentNextTechnology
        header={data?.nextTechnologySectionHeader}
        subHeader={data?.nextTechnologySectionSubHeader}
        image={data?.nextTechnologySectionBackgroundImage}
        button={data?.nextTechnologyButtonLink}
        NextTechnologyHeader="NextTechnologyHeader"
        NextTechnologysubHeader="NextTechnologysubHeader"
        NextTechnologyBackgroundImage="nextTechnologyBackgroundImage"
        ButtonLabel="buttonLabel"
        basePath={basePath}
      />
      <RequestFormPopup data={data?.pardotForm} images={images} />
    </div>
  );
};

export default TechnologyContent;
